<script context="module">
	export function updateWacp(analytics_storage, personalization_storage, ad_storage) {
		window.dataLayer.push({
			event: 'update-cookies-consent',
			analytics_storage,
			personalization_storage,
			ad_storage
		})
	}
</script>

<script lang="ts">
	import { t } from '@woltair/translations-package-fe'
	// import { createEventDispatcher } from 'svelte'
	import { fade } from 'svelte/transition'
	import { page } from '$app/stores'
	import { Form } from '$lib/form'
	import { SETTINGS, PRIVACY, DOCUMENTS, COOKIE_TERMS } from '$lib/routes'
	// const dispatch = createEventDispatcher()
	let value: string
	// function sendHideSignal() {
	// 	dispatch('message', {
	// 		showWacpBanner: false
	// 	})
	// }
</script>

<section
	class="wacp wacp-shadow max-w-screen fixed bottom-0 left-1/2 z-50 flex w-full -translate-x-1/2 flex-col
		items-center justify-between gap-1 border border-neutral-200 bg-white p-6 text-neutral-800 md:p-4 lg:bottom-6 lg:w-fit lg:rounded-xl lg:px-14 lg:py-10"
	in:fade
>
	<h4 class="m-0 !mt-0 text-center text-2.5xl font-extrabold lg:mb-10 lg:text-[2.7rem]">
		{t('web.cookies.privacyCare')}
	</h4>

	<p class="link-wrapper f-w-normal mb-6-res max-w-xl text-center text-sm leading-relaxed">
		{t('web.cookies.infotext', {
			settingsLink: `/${SETTINGS}/${PRIVACY}`,
			termsLink: `/${DOCUMENTS}/${COOKIE_TERMS}`
		})}
	</p>

	<div class="flex w-full flex-wrap items-center justify-center gap-2 lg:mt-0 lg:w-max">
		<Form cls="flex gap-3 justify-center items-center flex-wrap" action="/{SETTINGS}/{PRIVACY}">
			<input type="hidden" name="analytics" {value} />
			<input type="hidden" name="personalization" {value} />
			<input type="hidden" name="ad" {value} />
			<input type="hidden" name="referer" value={$page.url.href} />
			<button
				class="btn btn-filled-primary my-0 h-fit w-full min-w-full py-3.5 leading-none sm:w-fit sm:min-w-[20em]"
				data-testid="cookies-consent-accept"
				on:click={() => {
					value = 'granted'
					// sendHideSignal()
					updateWacp('granted', 'granted', 'granted')
				}}>{t('web.cookies.infotext.acceptAll')}</button
			>
			<button
				class="btn btn-outline-accent h-fit w-full bg-transparent py-3 leading-none sm:w-fit"
				data-testid="cookies-consent-reject"
				on:click={() => {
					value = 'denied'
					// sendHideSignal()
					updateWacp('denied', 'denied', 'denied')
				}}>{t('web.cookies.infotext.rejectAll')}</button
			>
			<a
				class="btn btn-outline-accent h-fit w-full gap-2 bg-transparent py-3 leading-none sm:w-fit"
				href="/{SETTINGS}/{PRIVACY}"
				data-testid="cookiesConsent-settings"
			>
				{t('web.cookies.infotext.settings')}
			</a>
		</Form>
	</div>
</section>

<style>
	.wacp-shadow {
		box-shadow: rgba(0, 0, 0, 0.25) 0px 7px 18px;
	}
</style>
